@import "../styles/variables";

.product {
  width: 50%;

  text-align: center;

  .productName {
    padding: 10px 0;
  }

  h4 {
    font-weight: bold;
    font-size: 1.25rem;
  }

  .label {
    position: absolute;
    top: 1rem;
    right: -1rem;
    font-size: 1rem;
    transform: rotate(45deg);
  }

  .linkBox {
    text-align: center;
    padding: 0.25rem 0;

    .link {
      font-size: 1.25rem;
      font-weight: bold;
      margin: 0 0.5rem;

      a {
        color: #ffffff;
      }
    }
  }

  .priceBox {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    .price {
      text-align: center;
      font-size: 1.25rem;

      del {
        padding-right: 10px;
      }
    }
  }

  .picture {
    img {
      padding: 20px;
      width: 100%;
      height: auto;
    }
  }

  &.stock-low {
  }

  &.stock-lack {
    opacity: 0.7;
  }

  &.stock-lot {
  }

  .stockBadge {
    margin-top: -20px;
    position: relative;
    display: block;
  }
}

@include media-breakpoint-up(md) {
  .product {
    width: 25%;

    border: 1px solid #ccc;
    margin-left: -1px;
    margin-top: -1px;
  }
}

@include media-breakpoint-up(xxl) {
  .product {
    width: 20%;

    border: 1px solid #ccc;
    margin-left: -1px;
    margin-top: -1px;
  }
}

@include media-breakpoint-down(lg) {
  .product {
    width: 33.3%;

    border: 1px solid #ccc;
    margin-left: -1px;
    margin-top: -1px;
  }
}

@include media-breakpoint-down(md) {
  .product {
    width: 50%;
  }
}

@include media-breakpoint-down(sm) {
  .product {
    width: 50%;

    &:nth-child(2n) {
      border-right: 0;
    }
  }
}
