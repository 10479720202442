@import "./variables";

.image,
.text {
  padding: 0;
  flex-shrink: 0;
  width: 50%;
}

.image {
  img {
    max-width: 100%;
    height: 100%;
  }
}

.text {
  //width: 50%;
  background: #ccc;
  padding: 0 100px 25px;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;

  p {
    font-size: small;
  }
}

@include media-breakpoint-down(md) {
  .image,
  .text {
    width: 100%;
  }

  .text {
    padding: 40px 40px 40px;
  }
}

.bestsellers {
  flex-wrap: nowrap !important;
  overflow: hidden !important;
}

.row {
  display: flex;
  flex-wrap: wrap;
  --bs-gutter-x: 0 !important;
}
