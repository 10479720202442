@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.image {
  text-align: center;
  //display: inline-block;
  width: 100%;

  img {
    width: 100%;
    //max-width: 700px;
  }
}

.gallery {
  display: flex;
  column-gap: 15px;
  row-gap: 20px;
  flex-wrap: wrap;
  justify-items: center;
  margin-bottom: 15px;

  picture {
    flex-grow: 1;
    width: 100%;
  }

  img {
    height: 100%;
    object-fit: cover;
  }
}

.gallery-2,
.gallery-4,
.gallery-6 {
  picture {
    width: calc(50% - 15px);
  }
}

.gallery-3,
.gallery-5 {
  picture {
    width: calc(33% - 15px);
  }
}

@include media-breakpoint-down(md) {
  .gallery {
    picture {
      width: 100%;
    }
  }
}

@include media-breakpoint-down(xl) {
  .gallery {
    picture {
      min-width: calc(50% - 15px);
    }
  }
}

.title {
  display: inline-block;
  font-style: italic;
}
